import { Routes } from '@angular/router';
import { MainpagePage } from './mainpage.page';
import {Menu36Page} from './menu36/menu36.component';
import {Menu37Page} from './menu37/menu37.component';
import {Menu38Page} from './menu38/menu38.component';
import {Menu39Page} from './menu39/menu39.component';
import {Menu41Page} from './menu41/menu41.component';
import {Menu42Page} from './menu42/menu42.component';
import {Menu43Page} from './menu43/menu43.component';
import {Menu44Page} from './menu44/menu44.component';
import {Menu48Page} from './menu48/menu48.component';
import {Menu49Page} from './menu49/menu49.component';
import {Menu50Page} from './menu50/menu50.component';
import {Menu51Page} from './menu51/menu51.component';
import {Menu52Page} from './menu52/menu52.component';
import {Menu53Page} from './menu53/menu53.component';
import {Menu54Page} from './menu54/menu54.component';
export const routes: Routes = [
{
 path: '',
 component: MainpagePage,
children: [
    {
        path: '36',
        component: Menu36Page
        },
        {
        path: '37',
        component: Menu37Page
        },
        {
        path: '38',
        component: Menu38Page
        },
        {
        path: '39',
        component: Menu39Page
        },
        {
        path: '41',
        component: Menu41Page
        },
        {
        path: '42',
        component: Menu42Page
        },
        {
        path: '43',
        component: Menu43Page
        },
        {
        path: '44',
        component: Menu44Page
        },
        {
        path: '48',
        component: Menu48Page
        },
        {
        path: '49',
        component: Menu49Page
        },
        {
        path: '50',
        component: Menu50Page
        },
        {
        path: '51',
        component: Menu51Page
        },
        {
            path: '52',
            component: Menu52Page
            },
            {
                path: '53',
                component: Menu53Page
                },
                {
                    path: '54',
                    component: Menu54Page
                    },
             ]
        }
        ];
        