import {​​​​​​​​ NgModule, ModuleWithProviders }​​​​​​​​ from'@angular/core';
 
import {​​​​​​​​ HighlightDirective }​​​​​​​​ from'../Directive/highlight.directive';
import {​​​​​​​​ LineChartDirective }​​​​​​​​ from'../Directive/linechart.directive';
import {​​​​​​​​ PieChartDirective }​​​​​​​​ from'../Directive/pie-chart.directive';
import {​​​​​​​​ ColumnChartDirective }​​​​​​​​ from'../Directive/column-chart.directive';
import {​​​​​​​​ BarChartDirective }​​​​​​​​ from'../Directive/bar-chart.directive';
import {​​​​​​​​ GaugeChartDirective }​​​​​​​​ from'../Directive/gauge-chart.directive';
import {​​​​​​​​ CandlestickChartDirective }​​​​​​​​ from'../Directive/candlestick-chart.directive';
import {​​​​​​​​ WaterfallChartDirective }​​​​​​​​ from'../Directive/waterfall-chart.directive';
import {​​​​​​​​ TableChartDirective }​​​​​​​​ from'../Directive/table-chart.directive';
import {​​​​​​​​ WordtreeChartDirective }​​​​​​​​ from'../Directive/wordtree-chart.directive';
 
@NgModule({​​​​​​​​
declarations: [
HighlightDirective,
LineChartDirective,
PieChartDirective,
ColumnChartDirective,
BarChartDirective,
GaugeChartDirective,
CandlestickChartDirective,
WaterfallChartDirective,
TableChartDirective,
WordtreeChartDirective
  ],
exports: [
HighlightDirective,
LineChartDirective,
PieChartDirective,
ColumnChartDirective,
BarChartDirective,
GaugeChartDirective,
CandlestickChartDirective,
WaterfallChartDirective,
TableChartDirective,
WordtreeChartDirective
  ]
}​​​​​​​​)
// export class SharedModule {
//   static forRoot(): ModuleWithProviders {
//     return {
//       ngModule: SharedModule,
     
//     };
//   }
// }
export class SharedModule {​​​​​​​​
  staticforRoot(): ModuleWithProviders<SharedModule> {​​​​​​​​
  return {​​​​​​​​
  ngModule:SharedModule,
  
      }​​​​​​​​;
    }​​​​​​​​
  }​​​​​​​​
