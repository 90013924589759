import { Directive , ElementRef,OnChanges,SimpleChanges, HostListener, Input, OnInit} from '@angular/core';
declare var google: any;
@Directive({
    selector: '[appColumnChart]'
})
export class ColumnChartDirective implements OnChanges {

    @Input('') appColumnChartConfig: any;
    @Input('') appColumnChartDT: any;
    public chart: any;
    constructor(private elRef: ElementRef) { }

    ngOnInit() {
        debugger;

        console.log("appconfig", this.appColumnChartConfig);
        google.load("visualization", "1.1", { packages: ["corechart"] });


    }

    ngOnChanges(changes: SimpleChanges) {

        setTimeout(() => {
            google.load("visualization", "1.1", { packages: ["corechart"] });
            google.setOnLoadCallback(this.drawChart(this.appColumnChartConfig.ChartConfiguration,
                this.appColumnChartConfig.moduleData, this.appColumnChartDT));
        }, 100);
  
    }

    public drawChart(ChartConfiguration, moduleData1, dT): any {
        debugger;

        var data: any;
        if (dT != null && dT != undefined) {
            google.load('visualization', '1.1', { packages: ['column', 'corechart'] });
            data = new google.visualization.DataTable(dT.value);

            /* var data = google.visualization.arrayToDataTable([
              ['Language', 'Speakers (in millions)'],
              ['German',  5.85],
              ['French',  1.66],
              ['Italian', 0.316],
              ['Romans', 0.0791]
            ]);
           */

            var addRowData = Object.keys(dT.value[0]);



            var NewData: any;
            NewData = $(addRowData).each(function (i) {
                addRowData[i];
            }).get().join(',');

            var array = NewData.split(',');
            for (var i = 0; i < Object.keys(dT.value[0]).length; i++) {


                if (i == 0) {
                    data.addColumn("string", Object.keys(dT.value[0])[i]);

                }
                else {

                    data.addColumn("number", Object.keys(dT.value[0])[i]);

                }
            }
            for (var i = 0; i < dT.value.length; i++) {
                var temp = [];
                for (var j = 0; j < array.length; j++) {
                    if (j == 0) {
                        temp.push(dT.value[i][array[j]]);
                    }
                    else {
                        temp.push(Number(dT.value[i][array[j]]));
                    }
                    console.log(temp);
                }
                data.addRow([temp][0]);

            }
        }

        if ((moduleData1.length != 0) && (dT == null || dT == undefined)) {

            google.load('visualization', '1.1', { packages: ['column', 'corechart'] });
            data = new google.visualization.DataTable(moduleData1);
            /* var data = google.visualization.arrayToDataTable([
              ['Language', 'Speakers (in millions)'],
              ['German',  5.85],
              ['French',  1.66],
              ['Italian', 0.316],
              ['Romans', 0.0791]
            ]);
           */

            var addRowData = Object.keys(moduleData1[0]);



            var NewData: any;
            NewData = $(addRowData).each(function (i) {
                addRowData[i];
            }).get().join(',');

            var array = NewData.split(',');
            for (var i = 0; i < Object.keys(moduleData1[0]).length; i++) {


                if (i == 0) {
                    data.addColumn("string", Object.keys(moduleData1[0])[i]);

                }
                else {

                    data.addColumn("number", Object.keys(moduleData1[0])[i]);

                }
            }
            for (var i = 0; i < moduleData1.length; i++) {
                var temp = [];
                for (var j = 0; j < array.length; j++) {
                    if (j == 0) {
                        temp.push(moduleData1[i][array[j]]);
                    }
                    else {
                        temp.push(Number(moduleData1[i][array[j]]));
                    }
                    console.log(temp);
                }
                data.addRow([temp][0]);

            }
            //Generalization implementation
        }
        else  //in case of no records 
        {
            google.load('visualization', '1.1', { packages: ['column', 'corechart'] });
            data = new google.visualization.arrayToDataTable([
                ['', { role: 'annotation' }],
                ['', '']
            ]); 
        }

        var options = {
            //generic properties
            title: ChartConfiguration.Title,
            titleTextStyle: { color: 'black', fontName: 'Roboto', fontSize: 12 },
            height: ChartConfiguration.Height,
            width: ChartConfiguration.Width,
            colors: Array.from(ChartConfiguration.ColorsRGB),
            chartArea: { left: 80, top: 10, width: "100%", height: "65%" },
            //  chartArea: { left: ChartConfiguration.ChartAreaLeft, top: ChartConfiguration.ChartAreaTop, width: ChartConfiguration.ChartAreaWidth, height: ChartConfiguration.Height },
            legend: { position: ChartConfiguration.LegendPosition },
            tooltip: { trigger: ChartConfiguration.ToolTipTrigger },
            animation: {
                duration: ChartConfiguration.AnimationDuration,
                easing: ChartConfiguration.AnimationEasing,
                startup: ChartConfiguration.AnimationStartup
            },

            vAxis: {
                maxValue: ChartConfiguration.VAxisMaxValue,
                minValue: ChartConfiguration.VAxisMinValue,
                title: ChartConfiguration.VAxisTitle,
                // titleTextStyle:
                // {
                //     color: ChartConfiguration.VAxisTitleColor
                // },
                textStyle: { fontSize: 10 },
                gridlines: {color: '#f5f5f5'},
                minorGridlines:{color: '#f5f5f5'}
            },

            hAxis: {
                title: ChartConfiguration.HAxisTitle,
                // titleTextStyle:
                // {
                //     color: ChartConfiguration.HAxisTitleColor
                // },
                textStyle: { fontSize: 10 },
                gridlines: {color: '#f5f5f5'},
                minorGridlines:{color: '#f5f5f5'}
            },

            // specific properties
            enableInteractivity: ChartConfiguration.EnableInteractivity,
            isStacked: ChartConfiguration.isStacked,
            dataOpacity: 1.0,
            bar: { groupWidth: ChartConfiguration.BarGroupWidth }
        };

        this.chart = new google.visualization.ColumnChart(document.getElementById(this.elRef.nativeElement.id));
        google.visualization.events.addListener(this.chart, 'animationfinish', titleCenter);
        google.visualization.events.addListener(this.chart, 'ready', titleCenter);
        this.chart.draw(data, options);

        function titleCenter() {
            $("text:contains(" + options.title + ")").attr({ 'x': '30%', 'y': '20' })
        }

        /*
        function resizeChart() {
            this.chart = new google.visualization.ColumnChart(document.getElementById(this.elRef.nativeElement.id));
            google.visualization.events.addListener(this.chart, 'animationfinish', titleCenter);
            google.visualization.events.addListener(this.chart, 'ready', titleCenter);
            this.chart.draw(data, options);

        }
        if (document.addEventListener) {
            window.addEventListener('resize', resizeChart);
        }
        */
    }
}
    /*
    $(window).resize(function() {
            this.drawChart(this.appColumnChartConfig.ChartConfiguration,
                this.appColumnChartConfig.moduleData, this.appColumnChartDT);
        });*/
