import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
// import { Headers, RequestOptions, ResponseContentType } from "@angular/common/http";
import { fromEvent, merge, of, from, throwError } from 'rxjs';
import { mapTo, catchError } from "rxjs/operators";

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
//import { ServerSideColumn } from '../Models/ServersideColumn';
//import { Module } from '../Models/Module';
//import { Observable } from 'rxjs/Observable';


const apiUrl = environment.apiUrl;

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Injectable(
    {
        providedIn: 'root'
    })
export class MainPageService {
    online$: Observable<boolean>;
    headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    filterData: '"Filter":{"Entity":"2"}';
    columnHeader: any;

    onlineFlag: any;
    Module: any;
    constructor(private _http: HttpClient) {
        this.onlineFlag = navigator.onLine;
        console.log(this.onlineFlag);
    }

    //getListModule(MenuID, UserName): Observable<Module[]> {
    //    return this._http.get<Module[]>(`${apiUrl}/${'api/Page/GetListModule?UserName=' + UserName + '&MenuNo=' + MenuID}`);
    //}
    getmodules(MenuID, UserName) {
        //alert('getmodules');
        //console.log(apiUrl);
        return this._http.get(`${apiUrl}/${'api/Page/GetModules?Id=' + MenuID + '&UserName=' + UserName}`);
    }
    getdata() {
        return this._http.get(`${'https://jsonplaceholder.typicode.com/photos'}`);
    }
    //GetColumnsForDataTable(MenuID, UserName): Observable<ServerSideColumn[]> {

    //    return this._http.get<ServerSideColumn[]>(`${apiUrl}/${'api/Page/GetColumnsForDataTable_AG_6?Id=' + MenuID + '&UserName=' + UserName}`, { responseType: 'json' });

    //}



    getPageMenuDetails(MenuID, UserName) {
        //alert('call');
        return this._http.get(`${apiUrl}/${'api/Page/GetPageMenuDetail?UserName=' + UserName + '&MenuNo=' + MenuID}`);
    }


    Savedata(ModuleID, data, username) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveModules_v1?id=' + ModuleID + '&username=' + username}`, data)

    }

    SaveDatatable(ModuleID, data, username) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveDataTable?id=' + ModuleID + '&username=' + username}`, data)

    }

    SaveSubmodules(ModuleID, form, dt, username) {
        var data = new FormData();
        data.append("Form", JSON.stringify(form));
        data.append("Tables", JSON.stringify(dt));
        return this._http.post(`${apiUrl}/${'api/Page/SaveSubmodules?id=' + ModuleID + '&username=' + username}`, data)
    }

    UpdateSubmodules(ModuleID: any, form: any, dt: any, ButtonId: number, username: string) {
        var data = new FormData();
        data.append("Form", JSON.stringify(form));
        data.append("Tables", JSON.stringify(dt));
        return this._http.post(`${apiUrl}/${'api/Page/SaveSubmodulesButtonConfig?Id=' + ModuleID + '&ButtonId=' + ButtonId + '&UserName=' + username}`, data)
    }

    Savedata1(ModuleID, data, username, httpdata) {

        //debugger;

        //alert("You are in Mainpage.service.ts " +username);

        var formData = new FormData();

        console.log('data :-' + data);
        console.log('File Name :- ' + httpdata.name + ' File Data :-' + httpdata.FileData);
        formData.append('data', data);
        formData.append(httpdata.name, httpdata.FileData);
        console.log(JSON.stringify(formData));
        return this._http.post(`${apiUrl}/${'api/Page/SaveModules?id=' + ModuleID + '&username=' + username}`, formData);
    }

    Savedata1_backup(ModuleID, data, username, httpdata) {
        // alert("You are in Mainpage.service.ts " +username);
        // var headers = new Headers();
        // headers.append("Accept", 'application/json');
        // headers.append('Content-Type', 'multipart/form-data');
        // const requestOptions = new RequestOptions({ headers: headers });

        data.append('blob', httpdata);
        console.log(data);
        //data.append('blob',httpdata);
        return this._http.post(`${apiUrl}/${'api/Page/SaveModules?id=' + ModuleID + '&username=' + username}`, data);
    }

    // return this._http.post(`${apiUrl}/${'api/Page/SaveModules?id='+ModuleID+'&username=' +username}`, data)


    postFile(fileToUpload: File, username, columnHeader) {
        const data: FormData = new FormData();
        data.append('fileKey', fileToUpload);
        // const data='{"file":"'+fileToUpload.name+'""}'
        console.log(data);
        return this._http.post(`${apiUrl}/${'api/Page/UploadJsonFile?columnHeader=' + columnHeader + '&UserName=' + username}`, data
        )/*
        .subscribe(
        data  => {
        console.log("POST Request is successful ", data);
        },
        error  => {

        console.log("Error", error);

        }

        );*/
    }

    postFile1(data: FormData, username, columnHeader) {

        return this._http.post(`${apiUrl}/${'api/Page/UploadJsonFile?columnHeader=' + columnHeader + '&UserName=' + username}`, data
        ).pipe(catchError(this.errorhandler));
    }
    public errorhandler(error: HttpErrorResponse) {
        console.log('error', error);
        return throwError(error.message);
    }

    SaveJsData(ModuleID, data, username) {

        const body = new HttpParams()

            .set('data', data);

        return this._http.post(`${apiUrl}/${'api/Page/SaveModuleJson?id=' + ModuleID + '&username=' + username}`, body, { responseType: 'text' })

    }

    exporttoexcel(menuid, UserName, data) {
        //console.log("data",JSON.stringify(data));
        //var datasend=JSON.stringify(data);
        //const body = new HttpParams()
        //    .set('data', data);
        return this._http.post(`${apiUrl}/${'api/Page/ExportToexcel?MenuId=' + menuid + '&UserName=' + UserName}`, data, { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json' } });
    }


    GetModulesWithFilter(MenuID, UserName, data) {
        console.log("You are in getmoduleswithfilter");
        return this._http.post(`${apiUrl}/${'api/Page/GetModulesWithFilters_v1?Id=' + MenuID + '&UserName=' + UserName}`, data, this.headers);

    }

    GetPaginatedTreeTable(MenuID, UserName, data) {
        console.log("You are in GetPaginatedTreeTable");
        return this._http.post(`${apiUrl}/${'api/Page/GetPaginatedTreeTable?Id=' + MenuID + '&UserName=' + UserName}`, data, this.headers);

    }

    SaveTreetable(ModuleID: number, data1: string, username: string, treetableModuleIndex?: number): any {
        var data = new FormData();
        data.append('files', data1);
        return this._http.post(`${apiUrl}/${'api/Page/SaveTreeTable?id=' + ModuleID + '&index=' + treetableModuleIndex + '&username=' + username}`, data);
    }


    //Get Mails
    GetMails(UserName) {
        debugger;
        return this._http.get(`${apiUrl}/` + `api/Email/GetMails?username=${UserName}`);

    }
    //Table Save Added On:May 17 2019
    SaveModule(ModuleID, data, username) {
        //const body=new HttpParams()
        //.set('data',data);
        //return this._http.post(`${apiUrl}/${'api/Page/SaveModuleJson?id=' + ModuleID + '&username=' + username}`, data, {responseType:'text', headers: { 'Content-Type': 'application/json; charset=utf-8'}  })
        return this._http.post(`${apiUrl}/${'api/Page/SaveModule?id=' + ModuleID + '&username=' + username}`, data)

    }

    GenerateReport(reporttype, username, data) {

        //const body=new HttpParams()
        //.set('data',data);

        //return this._http.post(`${apiUrl}/${'api/Page/SaveModuleJson?id=' + ModuleID + '&username=' + username}`, data, {responseType:'text', headers: { 'Content-Type': 'application/json; charset=utf-8'}  })
        //return this._http.post(`${apiUrl}/${'api/Report/ExportReport?reporttype=' + reporttype + '&username=' + username}`, data,this.headers)
        return this._http.post(`${apiUrl}/${'api/Report/ExportReport?reporttype=' + reporttype + '&username=' + username}`, data, { responseType: 'blob', headers: { 'Content-Type': 'application/json' } });
    }

    SendReport(reporttype, username, data) {
        return this._http.post(`${apiUrl}/${'api/Report/SendReport?reporttype=' + reporttype + '&username=' + username}`, data, { responseType: 'blob', headers: { 'Content-Type': 'application/json' } })
    }
    GenerateOTPEmail(Email) {
        return this._http.get(`${apiUrl}/${'api/Login/SendOTPEmail_v1?Email_Id=' + Email}`);
    }

    getDependentDropDownValue(ModuleDetailId, Id) {
        return this._http.get(`${apiUrl}/${'api/Page/GetDependentDropDownValue?ModuleDetailId=' + ModuleDetailId + '&Id=' + Id}`)
    }

    GetDependentTextBoxValue(moduledetailid, Param1, Param2, username) {
        return this._http.get(`${apiUrl}/${'api/Page/GetDependentTextBoxValue?ModuleDetailId=' + moduledetailid + '&Param1=' + Param1 + '&Param2=' + Param2 + '&Username=' + username}`)
    }
    GetDashboardMenu(username) {
        return this._http.get(`${apiUrl}/${'api/Menu/GetDashboardMenu?Username=' + username}`)
    }
    GetDependentDropDownThroughValue(ModuleDetailId, Id) {
        return this._http.get(`${apiUrl}/${'api/Page/GetDependentDropDownThroughValue?ModuleDetailId=' + ModuleDetailId + '&Id=' + Id}`)
    }


    GetFabMenus(UserName, ParentMenuID, roleid) {

        let data: any;
        return this._http.get(`${apiUrl}/${'api/Menu/GetFabMenus?username=' + UserName + '&ParentMenuID=' + ParentMenuID + '&RoleId=' + roleid}`);

    }
    UpdateData(ModuleID: any, ButtonId: number, data: any, username: string) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveModulesButtonConfig?Id=' + ModuleID + '&ButtonId=' + ButtonId + '&UserName=' + username}`, data)
    }

    UpdateDatatable(ModuleID: any, ButtonId: number, data: any, username: string) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveDatatableButtonConfig?Id=' + ModuleID + '&ButtonId=' + ButtonId + '&UserName=' + username}`, data)
    }

    //for get the feedback data
    GetFeedback(MenuID, UserName, data) {
        debugger;
        console.log("You are in getmoduleswithfilter");
        return this._http.post(`${apiUrl}/${'api/Page/GetFeedback?Id=' + MenuID + '&UserName=' + UserName}`, data, this.headers);

    }
    //to save the feedback data
    SaveFeedback(MenuId, data, username) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveFeedback?id=' + MenuId + '&username=' + username}`, data)

    }
    RunWorkflowTask(ID, username, data) {
        return this._http.post(`${apiUrl}/api/Page/RunWorkflowTask?RowValue=${ID}&UserName=${username}`, data);
    }

    getDependentFilterDropDownValue(ModuleDetailId, Id) {
        return this._http.get(`${apiUrl}/${'api/Page/GetDependentFilterDropDownValue?ParameterId=' + ModuleDetailId + '&Id=' + Id}`)
    }

    getModulesToShow(MenuID, UserName) {
        return this._http.get(`${apiUrl}/${'api/Page/GetModulesToShow?MenuID=' + MenuID + '&UserName=' + UserName}`)
    }

    getModuleDetailIdToHide(MenuID, UserName) {
        return this._http.get(`${apiUrl}/${'api/Page/GetModuleDetailIDToHide?MenuID=' + MenuID + '&UserName=' + UserName}`)
    }
    Savedata_file(fileToUpload: Array<File>, ModuleID, data, username) {
        const data1: FormData = new FormData();
        data1.append('data', JSON.stringify(data));
        fileToUpload.forEach((item) => {
            data1.append('file[]', item);
        })
        return this._http.post(`${apiUrl}/${'api/Page/SaveModules_v1?id=' + ModuleID + '&username=' + username}`,
            data1)

    }


    SavePrimeNgDatatable(ModuleID, data1, username) {
        var data = new FormData();
        data.append('files', data1);
        return this._http.post(`${apiUrl}/${'api/Page/SavePrimeNgTable?id=' + ModuleID + '&username=' + username}`, data)

    }
    SavePrimeNgTable(MenuID, ModuleID, data1, username, filter) {

        var data = new FormData();
        data.append('files', data1);
        data.append('filter', filter);
        return this._http.post(`${apiUrl}/${'api/Page/SavePrimeNgTable?id=' + ModuleID + '&username=' + username + '&MenuId=' + MenuID}`, data);
    }
    SavePrimeNgTable1(ModuleID, MenuId, data, username) {

        return this._http.post(`${apiUrl}/${'api/Page/SavePrimeNgTable?Id=' + ModuleID + '&menuId=' + MenuId + '&UserName=' + username}`, data);
    }
    GetPivotTableModule(MenuId: number, ID: number, UserName: string, filter: any) {
        var data = {};
        data["Filter"] = filter;
        return this._http.post(`${apiUrl}/${'api/Page/GetPivotTableModule?Id=' + ID + '&MenuId=' + MenuId + '&UserName=' + UserName}`, data);
    }

    UpdateDatatablewithfilter(ModuleID: any, menuId, ButtonId: number, data: any, username: string) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveDatatableButtonConfig?Id=' + ModuleID + '&menuId=' + menuId + '&ButtonId=' + ButtonId + '&UserName=' + username}`, data, this.headers)
    }

    getImage(path: string) {
        return this._http.get(`${apiUrl}/${'api/Page/GetImage?path=' + path}`, { responseType: 'blob', headers: { 'Content-Type': 'image/jpeg' } });
    }
    //Notifications:
    getNotifications(UserName) {
        return this._http.get(`${apiUrl}/${'api/Page/GetDesktopNotifications?UserName=' + UserName}`);
    }
    AddReadInAppNotifications(UserName, InAppID) {
        return this._http.post(`${apiUrl}/${'api/Page/AddReadInAppNotifications?UserName=' + UserName +
            '&InAppID=' + InAppID}`,
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
            }
        );

    }
    GetBadgeValue(UserName) {
        return this._http.get(`${apiUrl}/${'api/Page/GetBadgeValue?username=' + UserName}`);
    }

    MultipleDependentDropdown(moduledetailid, value, sp, username) {
        var data = {};
        data["value"] = value;
        return this._http.post(`${apiUrl}/${'api/Page/GetValuesForMultipleDependantDropdown?Id=' + moduledetailid + '&DependantSP=' + sp + '&username=' + username}`, data);

    }


    AutoPopulateMultipleFields(value: any, AutoPopulateSP: any, UserName: string) {
        var data = {};
        data["value"] = value;
        return this._http.post(`${apiUrl}/${'api/Page/AutoPopulateMultipleFields?AutoPopulateSP=' + AutoPopulateSP + '&username=' + UserName}`, data);
    }


    uploadFile(file, username, columnHeader) {
        const data: FormData = new FormData();
        data.append('file', file, file.Name);
        return this._http.post(`${apiUrl}/${'api/Page/UploadJsonFile?columnHeader=' + columnHeader + '&UserName=' + username}`, data)
    }

    exporttoexcelModulewise(moduleId, UserName, data) {
        return this._http.post(`${apiUrl}/${'api/Page/ExportToexcelModulewise?ModuleId=' + moduleId + '&UserName=' + UserName}`, data, { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json' } });
    }


    //test:ankita
    UpdatePrimeNgTables(MenuID, ModuleID, data1, username, ModuleName, otModuleName, filepath) {

        var data = new FormData();
        data.append('data', JSON.stringify(data1));
        data.append('filepath', filepath);
        data.append('otModuleName', JSON.stringify(otModuleName));
        // var datasend={​​​​​​​​}​​​​​​​​;
        debugger;
        return this._http.post(`${apiUrl}​​​​​​​​/${'api/Page/UpdateExcel?Id=' + ModuleID + '&username=' + username + '&MenuId=' + MenuID + '&ModuleName=' + ModuleName}​​​​​​​​`, data);
    }​​​​​​​​
}
