import { PendingChangesGuard } from '../Services/pending-changes.guard';   // added by Karan to detect changes before unloading page 10-6-21
import {Menu36Page} from './menu36/menu36.component';
import {Menu37Page} from './menu37/menu37.component';
import {Menu38Page} from './menu38/menu38.component';
import {Menu39Page} from './menu39/menu39.component';
import {Menu41Page} from './menu41/menu41.component';
import {Menu42Page} from './menu42/menu42.component';
import {Menu43Page} from './menu43/menu43.component';
import {Menu44Page} from './menu44/menu44.component';
import {Menu48Page} from './menu48/menu48.component';
import {Menu49Page} from './menu49/menu49.component';
import {Menu50Page} from './menu50/menu50.component';
import {Menu51Page} from './menu51/menu51.component';
import {Menu52Page} from './menu52/menu52.component';
import {Menu53Page} from './menu53/menu53.component';
import {Menu54Page} from './menu54/menu54.component';
import {TreeModule} from 'primeng/tree';
import { NgModule } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule ,HashLocationStrategy, Location, LocationStrategy} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { routes } from './mainpage.routes';
import { MainpagePage } from './mainpage.page';
import {CustomService} from '../Services/custom-service.service'
import { Data } from '../Services/data.service';
// import { MatPaginatorModule } from '@angular/material';
import { IonicStorageModule } from '@ionic/storage';
import { SharedModule } from '../shared/shared.module';
//import { AgmCoreModule } from '@agm/core';
import {TableModule} from 'primeng/Table'; 
import {PaginatorModule} from 'primeng/paginator';
import {DropdownModule} from 'primeng/dropdown';        //primeng select module
import {MultiSelectModule} from 'primeng/multiselect';  //pmg multiselect
import {AccordionModule} from 'primeng/accordion';      //pmg accordion necessary
import {TreeTableModule} from 'primeng/treetable';
import {CalendarModule} from 'primeng/calendar';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FileUploadModule} from 'primeng/fileupload';
import {ButtonModule} from 'primeng/button';
import {PanelModule} from 'primeng/panel';
import {InputNumberModule} from 'primeng/inputnumber';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {CardModule} from 'primeng/card';
import {DialogModule} from 'primeng/dialog';
import {MenuModule} from 'primeng/menu';
import {TabViewModule} from 'primeng/tabview';
import { GoogleMapLatLongComponent } from '../google-map-lat-long/google-map-lat-long.component';
import {DataViewModule} from 'primeng/dataview';
import { ChipModule } from 'primeng/chip';
import { Chat } from '../components/ACS/Chat';
import { TokenManager } from '../components/ACS/TokenManager';
import { CommunicationClient } from '../components/ACS/CommunicationClient';
import { UserManager } from '../components/ACS/UserManager';
import { VoiceCall } from '../components/ACS/VoiceCall';
import { VideoCall } from '../components/ACS/VideoCall';
import { CallCard } from '../components/ACS/CallCard';
import {ContextMenuModule} from 'primeng/contextmenu';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {SplitButtonModule} from 'primeng/splitbutton';
import{ChatComponent} from '../components/ACS/chat-component/chat-component.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
      IonicModule,
      RouterModule.forChild(routes),
      SharedModule,
      ReactiveFormsModule,
       MultiSelectModule,
      AccordionModule,
      DropdownModule,CardModule,
      PanelMenuModule,OverlayPanelModule,SplitButtonModule,
      MenubarModule,BreadcrumbModule,MenuModule,TabViewModule,
      TableModule,PaginatorModule,TreeTableModule,InputNumberModule,PanelModule,ButtonModule,FileUploadModule,
     RadioButtonModule,CheckboxModule,InputTextModule,CalendarModule,DialogModule,DataViewModule,TreeModule,ChipModule,ContextMenuModule,VirtualScrollerModule,//MatMomentDateModule,
      IonicStorageModule.forRoot()
      /* AgmCoreModule.forRoot({
        apiKey: 'AIzaSyBrfK8btHhw4Xfc9EjNJjwYilg0t0s2DcI',//api key
        libraries: ['places']
       }) */

    ],
    providers: [Data,CustomService,Chat,ChatComponent,
      TokenManager,CommunicationClient,UserManager,VoiceCall,VideoCall,CallCard, PendingChangesGuard,{provide: LocationStrategy, useClass: HashLocationStrategy}
//,
      //{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } } 
],
declarations: [MainpagePage,GoogleMapLatLongComponent,ChatComponent,
  Menu36Page,Menu37Page,Menu38Page,Menu39Page,Menu41Page,Menu42Page,Menu43Page,Menu44Page,
  Menu48Page,Menu49Page,Menu50Page,Menu51Page,Menu52Page,Menu53Page,Menu54Page],
    entryComponents: []
})
export class MainpagePageModule {}
