import { Component, OnInit, Type, NgModuleFactory, Compiler, ViewChild } from '@angular/core';
import { ActivatedRoute, Routes, Route } from '@angular/router';
import { MenuService } from '../Services/menu.service';
import { AzureCommunication } from '../Services/AzureCommunication.service';
import { Router } from '@angular/router';
//import { Page1Component } from './page1/page1.component';
import { MenuItem } from 'primeng/api';
import { Events } from '../Services/events.service';
import { AuthenticationService } from '../Services/authentication.service';
// import { labeledStatement } from 'node_modules_1/@babel/types/lib';
import { MenuController } from '@ionic/angular';
import { Chat } from '../components/ACS/Chat';
import { debug, timeStamp } from 'console';
import { VoiceCall } from '../components/ACS/VoiceCall'
import { VirtualScroller } from 'primeng/virtualscroller';
import { AUTO_STYLE } from '@angular/animations';
import { ChatComponent } from '../components/ACS/chat-component/chat-component.component';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.page.html',
  styleUrls: ['./mainpage.page.scss'],
})
export class MainpagePage implements OnInit {
  UserName: string;
  ID: number;
  SubMenu: any;
  items: MenuItem[];
  MenuData: any;
  ParentID: any;
  breadcrumbsitems: any[] = [];



  breadcrumbshome = { icon: 'pi pi-home' };


  endpointUrl: string;
  connectionString: string;

  chatopen: boolean = false;//chat box
  contactopen: boolean = false;
  conversationopen: boolean = false;
  contactlistopen: boolean = false;
  GroupChatOpen: boolean = false;

  activeThreadParticipants: any = []
  contactList: any = [];
  allContacts: any = [];
  messageText: any;

  conversation: any[];
  virtualConversation: any[];
  participantName: any;
  ACSUserId: string;

  threadId: string;
  initiator: any;
  groupContacts: any;
  participantUserId: any;
  showCallCard: boolean = false;
  MicStatus: string = "Mute";
  showIncomingCallCard: boolean = false;
  chatMessageContextMenuItems: {}[];
  contactContextMenuItems: {}[];
  selectedMessage: any;

  @ViewChild('chatbox', { static: false }) chatbox: VirtualScroller;
  messageReceived: any;
  //MenuId: number=1;
  constructor(private activatedroute: ActivatedRoute, private menuservice: MenuService,
    private router: Router, public events: Events,
    private menu: MenuController,
    private _compiler: Compiler,
    private authentication: AuthenticationService,
    private Chat: Chat,
    private Call: VoiceCall,
    private ACS: AzureCommunication) {
    console.log('beforactive', activatedroute.routeConfig);
    this.events.publish('PageName', '');

  }

  ngOnInit() {
    
    
    var encrypted = localStorage.getItem('username');
    this.UserName = localStorage.getItem('username');
    this.UserName = encrypted;
    console.log(this.UserName);

    this.initiator = {};
    this.initiator["user"] = { communicationUserId: this.ACSUserId };
    this.initiator["displayName"] = this.UserName;
    this.initiator["UserId"] = localStorage.getItem("UserId");


    this.menuservice.getSubGroup(this.UserName).subscribe(data => {

      console.log("api", data);
      this.runRecursive(data);
      console.log(data);
      debugger;
      var resultArray = Object.keys(data).map(function (personNamedIndex) {
        let person = data[personNamedIndex];
        // do something with person
        return person;
      });
      resultArray.forEach(element => {
        element.icon = null;
        if (element.items != null) {
          element.items.forEach(subelement => {
            subelement.icon = null;
          });
        }
        if (element.routerLink != null && element.routerLink != "") {
          var routerLink = [];
          routerLink.push(element.routerLink);
          element.routerLink = routerLink;
        }
      });
      console.log('resulted', resultArray);
      this.MenuData = resultArray;
      console.log("Menudata1", this.MenuData);
    });

    var CacheSubMenu = localStorage.getItem('SubMenu');
    if (CacheSubMenu != "null") {
      CacheSubMenu = JSON.parse(localStorage.getItem('SubMenu'))
      this.SubMenu = CacheSubMenu;

    }

    this.ACS.getActiveThreads(this.UserName).subscribe(data => {///Active participants
      this.activeThreadParticipants = data;
    })

    this.ACS.getContactList(this.UserName).subscribe(data => {////All contacts
      console.log(data);
      this.contactList = data;
    });

    this.ACS.getContactsForGroupChat(this.UserName).subscribe(data => {
      this.allContacts = data;
    })

    this.Chat.setChatClient(this.connectionString, this.endpointUrl, this.initiator);
    this.Call.setCallAgent(this.connectionString, this.initiator);
    this.showIncomingCallCard = this.Call.showIncomingCallCard;
  }
  ngAfterViewChecked() {
    this.showIncomingCallCard = this.Call.showIncomingCallCard;

  }
  ngAfterViewInit() {
    let menuitem = document.getElementsByClassName("ui-menuitem-link");
    if (menuitem[0] != undefined)
      menuitem[0].classList.add("active");

    
  }

  ionViewWillEnter() {
    //alert();
  }

  ionViewDidEnter() {

  }

  runRecursive(input) {
    if (input != null) {

      if (input.length > 0) {
        for (var i = 0, l = input.length; i < l; i++) {
          let current = input[i];
          let menuName = current.label;

          if (current.command != null) {
            let Id = current.ID;

            current.command = (onclick) => {
              this.getChildMenus(`${Id}`, `${menuName}`, `${current.IsParent}`)
            };

          }
          else {
            console.log("SIDE MENU", current);
            current.command = (onclick) => { this.getChildMenus('', `${menuName}`, `${current.IsParent}`) };
          }
          this.runRecursive(current.items);
        };
      }
    }

  };

  logout() {
    this.authentication.logout();
  }
  home() {
    window.location.href = "/menu/first";
  }
  // back()
  // {
  //         this._location.back();
  // }
  //Add new user
  printvalue(getvalue) {
    let newvalue = { label: getvalue.target.textContent };
    this.breadcrumbsitems = [];
    console.log(getvalue.target.textContent);
    this.breadcrumbsitems.push(newvalue);
  }
  getChildMenus(current, menuName, IsParent) {
    if (current != "") {
      this.menuservice.getmenus(current, this.UserName)
        .subscribe(data => {
          this.SubMenu = data;
          console.log('submenus', this.SubMenu);
          localStorage.setItem("SubMenu", JSON.stringify(this.SubMenu));
          this.events.publish('PageName', menuName);

          this.SubMenu[0].styleClass = "active";//to highlight first submenu

          this.breadcrumbsitems.push({ key: 'label', value: this.SubMenu[0].MenuName });
          console.log(this.breadcrumbsitems);
        });
      this.menu.toggle();

    }
    else {
      this.events.publish('PageName', menuName);
      if (IsParent == "false")
        this.menu.toggle();
      this.SubMenu = null;
      localStorage.setItem("SubMenu", null);
    }
  }

  activeMenu(event) {
    let node;
    if (event.target.tagName === "A") {
      node = event.target;
    } else {
      node = event.target.parentNode;
    }
    let menuitem = document.getElementsByClassName("ui-menuitem-link");
    for (let i = 0; i < menuitem.length; i++) {
      menuitem[i].classList.remove("active");

    }
    node.classList.add("active");
  }



  
}

