import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { AzureCommunication } from '../../../Services/AzureCommunication.service';
import { Chat } from '../Chat';
import { VoiceCall } from '../VoiceCall'
import { VirtualScroller } from 'primeng/virtualscroller';
import { VideoCall } from '../VideoCall';

@Component({
  selector: 'app-chat-component',
  templateUrl: './chat-component.component.html',
  styleUrls: ['./chat-component.component.scss'],
})
export class ChatComponent implements OnInit {

  endpointUrl: string;
  connectionString: string;

  chatopen: boolean = false;//chat box
  contactopen: boolean = false;
  conversationopen: boolean = false;
  contactlistopen: boolean = false;
  GroupChatOpen: boolean = false;

  activeThreadParticipants: any = []
  contactList: any = [];
  allContacts: any = [];
  messageText: any;

  conversation: any[];
  virtualConversation: any[];
  participantName: any;
  ACSUserId: string;

  threadId: string;
  initiator: any;
  groupContacts: any;
  participantUserId: any;

  showCallCard: boolean = false;
  showLocalVideoCard:boolean=false;
  showRemoteVideoCard:boolean=false;

  MicStatus: string = "Mute";
  showIncomingCallCard: boolean = false;
  showIncomingVideoCallCard: boolean=false;
  chatMessageContextMenuItems: {}[];
  contactContextMenuItems: {}[];
  selectedMessage: any;

  @ViewChild('chatbox', { static: false }) chatbox: VirtualScroller;

  messageReceived: any;
  UserName: string;
  totalMessages: any;
  searchRecentContacts: any[];
  searchAllContacts: any[];
  allThreads: any;
  
  constructor(private Chat: Chat,
    private Call: VoiceCall,
    private VideoCall:VideoCall,
    private ACS: AzureCommunication) { }

  ngOnInit() {

    this.chatMessageContextMenuItems = [
      {
        label: 'Delete',
        icon: 'pi pi-fw pi-trash',
        command: (event) => { this.deleteMessage(this.selectedMessage); }
      }];

    this.contactContextMenuItems = [
      {
        label: 'Delete',
        icon: 'pi pi-fw pi-trash',
        command: (event) => { this.deleteMessage(this.selectedMessage); }
      }];

    this.connectionString = "endpoint=https://chatapptest.communication.azure.com/;accesskey=05tg2gMhjPpht5w+x9KftqZpLiRCFiiyrJUdTvOdWlzLZs23B9OHHafoqzEPQrfxqKVUa+4TJ4MV+AcB24pfRA==";
    this.endpointUrl = "https://chatapptest.communication.azure.com/";

    var encrypted = localStorage.getItem('username');
    this.UserName = localStorage.getItem('username');
    this.ACSUserId = localStorage.getItem('ACSUserId');
    this.UserName = encrypted;
    console.log(this.UserName);

    this.initiator = {};
    this.initiator["user"] = { communicationUserId: this.ACSUserId };
    this.initiator["displayName"] = this.UserName;
    this.initiator["UserId"] = localStorage.getItem("UserId");

    this.ACS.getActiveThreads(this.UserName).subscribe(data => {///Active participants
      this.activeThreadParticipants = data;
      this.totalMessages=this.activeThreadParticipants.map(p=>p.UnreadMessageCount).reduce(function(a,b)
      {//show total unread messages when page load
        if(a==undefined)
          a=0;

        if(b==undefined)
          b=0;
        let sum=a+b;
        return sum;
      });
    })

    this.ACS.getContactList(this.UserName).subscribe(data => {////active contacts
      console.log(data);
      this.contactList = data;
      let list=[...this.contactList];
      this.searchRecentContacts=list;
    });

    this.ACS.getContactsForGroupChat(this.UserName).subscribe(data => {///all contacts
      this.allContacts = data;
      let list=[...this.allContacts];
      this.searchAllContacts=list;
    })

    this.Chat.setChatClient(this.connectionString, this.endpointUrl, this.initiator).then(
      ()=>this.getAllThreads()//sort by last received message
    );
    

    this.Call.setCallAgent(this.connectionString, this.initiator);
    this.VideoCall.setCallAgent(this.connectionString, this.initiator);
    this.showIncomingCallCard = this.Call.showIncomingCallCard;
    this.showIncomingVideoCallCard=this.VideoCall.showIncomingCallCard;
  }


  ngAfterViewChecked() {
    //this.showIncomingCallCard = this.Call.showIncomingCallCard;
    this.showIncomingVideoCallCard=this.VideoCall.showIncomingCallCard;
  }
  ngAfterViewInit() {
    
    this.Chat.messageEvent.subscribe((response) => {
      if (this.threadId != undefined && this.threadId != null && this.threadId != "") {
        this.receiveMessage(response);
      }
      else {
        this.showUnreadMessageCount(response);
      }
      this.getAllThreads();//sort by last received message
    })
    
  }

  getAllThreads()//sort by last received message
  {
    
    let allThreads=this.Chat.getListOfThreads();
    let activeParticipants=this.activeThreadParticipants;
    let promise=new Promise((resolve,reject)=>{
      setTimeout(function()  {
        resolve(allThreads);
      }, 10);
    });
    promise.then(function(allThreads:any[]){
      activeParticipants.map(participant => {
        let thread=allThreads.find(t=>t.id==participant.threadId);
        participant.lastMessageReceivedOn=thread.lastMessageReceivedOn;
      });
      activeParticipants.sort(function(a,b){return a.lastMessageReceivedOn < b.lastMessageReceivedOn?1:-1})
    })
  }

  

  loadMessagesLazy(event) {
    console.log("Lazy Load", event);
    setTimeout(() => {
      //load data of required page
      let loadedConversation = this.conversation.slice(event.first, (event.first + event.rows));

      //populate page of virtual cars
      Array.prototype.splice.apply(this.virtualConversation, [...[event.first, event.rows], ...loadedConversation]);

      //trigger change detection
      this.virtualConversation = [...this.virtualConversation];
    }, 1000);
    console.log(this.chatbox.totalRecords);
  }

  async sendMessage(message: any, c) {
    if (message) {
      console.log("SendMessage", c);
      let sentMessage = await this.Chat.sendMessage(message, this.threadId, this.UserName);
      let conversation = [...this.conversation];
      conversation.splice(this.conversation.length, 0, sentMessage);
      this.conversation = conversation;
      this.messageText = "";
      setTimeout(() => {
        this.chatbox.scrollToIndex(this.conversation.length, 'auto');
      }, 100);
    }
    else {
      return;
    }
  }

  async receiveMessage(messageObj)//for currently opened chat box
  {
    if (messageObj.sender.communicationUserId != this.ACSUserId && messageObj.threadId == this.threadId) {
      let message = await this.Chat.getNewMessage(this.threadId, messageObj.id);
      //alert("received");
      //push new message received to conversation array
      let conversation = [...this.conversation];
      conversation.splice(this.conversation.length, 0, message);
      this.conversation = conversation;

      setTimeout(() => {
        this.chatbox.scrollToIndex(conversation.length, 'auto');
        // alert("scroll")
      }, 5000);
    }

  }

  async openChat(thread, participantName) {
    let currentChat = this.activeThreadParticipants.find(p => p.threadId == thread.threadId);
    if (currentChat.UnreadMessageCount != undefined || currentChat.UnreadMessageCount > 0) {
      this.totalMessages=this.totalMessages-currentChat.UnreadMessageCount;
      currentChat.UnreadMessageCount = 0;
      console.log("Thread",thread);
      this.UpdateUnreadMessageCount(thread.UserId,thread.threadId,currentChat.UnreadMessageCount);
    }

    this.conversation = [];
    this.chatopen = true;
    this.participantName = participantName;
    this.participantUserId = thread.id;

    this.threadId = thread.threadId
    let conversation = await this.Chat.getMessages(this.threadId);
    conversation = conversation.map(c => c.hasOwnProperty('deletedOn') ? { ...c, content: { message: 'This message was deleted' } } : c);
    this.conversation = conversation.sort(function (a, b) { return a.createdOn > b.createdOn ? 1 : -1 });//sort messages ascending order
    console.log(this.conversation);
    setTimeout(() => {
      this.chatbox.scrollToIndex(this.conversation.length, 'auto');
    }, 10);

  }




  async openAndAddChat(participant) {//start new conversation
    this.conversation = [];
    this.chatopen = true;//opens chat window
    this.participantName = participant.displayName;
    participant.threadId = await this.Chat.getThreadId(this.connectionString, this.endpointUrl, this.initiator);//creates new thread for new participant

    this.threadId = participant.threadId;

    this.ACS.addNewParticipant(this.initiator["UserId"], participant.threadId).subscribe(data => {
      //adds new participant in the database with thread id
    });
    this.ACS.addNewParticipant(participant.UserId, participant.threadId).subscribe(data => {
      //adds new participant in the database with thread id
    });

    this.ACS.getActiveThreads(this.UserName).subscribe(data => {///Active participants
      this.activeThreadParticipants = data;
    })

    this.ACS.getContactList(this.UserName).subscribe(data => {////All contacts
      //console.log(data);
      this.contactList = data;
    });



    await this.Chat.addParticipant(participant, this.connectionString);//adds new particpiant to ACS thread
    this.contactopen = !this.contactopen;

  }

  UpdateUnreadMessageCount(participantId,threadId,messageCount)//updates unread message count to database
  {
    this.ACS.updateUnreadMessagesCount(participantId,threadId,messageCount).subscribe(response=>{
      console.log("Unread",response)
      this.totalMessages=this.activeThreadParticipants.map(p=>p.UnreadMessageCount).reduce(function(a,b)
      {
        if(a==undefined)
          a=0;

        if(b==undefined)
          b=0;
        let sum=a+b;
        return sum;
      });
      console.log("total",this.totalMessages);
    });
  }

  addParticipant(participant) {//adds participant while greating a new group
    var exists = this.groupContacts.includes(participant);
    if (exists) {
      var removed = this.groupContacts.filter(function (value, index, arr) {
        return value != participant
      });
      this.groupContacts = removed;
    }
    else {
      this.groupContacts.push(participant);
    }
  }

  async createGroup() {//create chat group
    var threadId = await this.Chat.getThreadId(this.connectionString, this.endpointUrl, this.initiator);
    this.ACS.addNewParticipant(this.initiator["UserId"], threadId).subscribe(data => {
      //adds new participant in the database with thread id
    });
    this.groupContacts.forEach(participant => {
      participant.threadId = threadId;
      this.Chat.addParticipant(participant, this.connectionString);

      this.ACS.addNewParticipant(participant.UserId, participant.threadId).subscribe(data => {
        //adds new participant in the database with thread id
      });
    });
    this.contactopen=true;
    this.GroupChatOpen=false;
  }

  onRemoveParticipant(participant) {//remove participant while creating a new chat group
    console.log("Remove", participant);
    var removed = this.groupContacts.filter(function (value, index, arr) { return value != participant });
    this.groupContacts = removed;
    console.log(this.groupContacts);
  }

  searchItems(items: any[], searchText) {
    debugger;
    if (searchText != "") {
      var searchedItems = items.filter(i => i.displayName.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
      if(this.contactlistopen)
        this.contactList = searchedItems;
      else if(this.GroupChatOpen)
        this.allContacts=searchedItems;
    }
    else {
      this.contactList=this.searchRecentContacts;
      this.allContacts=this.searchAllContacts;
    }
  }


  OpenCloseChatandContact(contactopen, chatopen) {//open/close active contact list
    this.groupContacts = [];
    if (contactopen == true && chatopen == true) {
      this.contactopen = !this.contactopen;
      this.chatopen = !this.chatopen;
    }
    else if (contactopen == false && chatopen == false) {
      this.contactopen = !this.contactopen;
      this.threadId = null;//to reset thread
    }
    else if (contactopen == true && chatopen == false) {
      this.contactopen = !this.contactopen;
      this.threadId = null;//to reset thread
    }
    else if (contactopen == false && chatopen == true) {
      this.contactopen = !this.contactopen;
      this.chatopen = !this.chatopen;
    }
    this.ACS.getActiveThreads(this.UserName).subscribe(data => {///Active participants
      this.activeThreadParticipants = data;
      this.getAllThreads();//sort by last received message
      let activeParticipants = [...this.activeThreadParticipants];
      
      let array = this.activeThreadParticipants.map(function (participant) {
        let p = activeParticipants.find(a => a.threadId == participant.threadId);
        if (p.UnreadMessageCount != undefined) {
          participant.UnreadMessageCount = p.UnreadMessageCount;
        }
        return participant;
      });
      
      console.log(array);
    })
    
    this.ACS.getContactList(this.UserName).subscribe(data => {////All contacts
      this.contactList = data;
    });
    this.contactlistopen = false;
    this.GroupChatOpen = false;
  }

  async startCall(participantId) {
    
    this.showCallCard = true;
    this.Call.startCall(participantId);
  }

  async endCall(participantId) 
  {
    this.showCallCard = false;
    this.showIncomingCallCard = false;
    this.showLocalVideoCard=false;
    this.showIncomingVideoCallCard = false;
    //this.Call.hangUp();
    this.VideoCall.hangUp();
  }

  async startVideoCall(participantId)
  {
    this.showLocalVideoCard = true;
    let view=await this.VideoCall.startVideoCall(participantId);
    document.getElementById("myVideo").appendChild(view.target);
    console.log("Video view",view);
  }

  acceptCallButton()
  {
    this.VideoCall.acceptCallButton();
  }

  muteCall() 
  {
    if (this.MicStatus == "Unmute")
      this.MicStatus = "Mute";
    else
      this.MicStatus = "Unmute";
  }

  GetSelectedMessage(message) {
    console.log("Selected MEssage", message);
    this.selectedMessage = message;
  }

  deleteMessage(selectedMessage) {
    this.Chat.deleteMessage(this.threadId, selectedMessage);
    selectedMessage.content.message = "This message is deleted"
    //console.log(this.conversation.indexOf(selectedMessage))
  }

  showUnreadMessageCount(message) {//unread message count when new message received
    let participant = this.activeThreadParticipants.find(p => p.threadId == message.threadId);

    if (participant.UnreadMessageCount == undefined || participant.UnreadMessageCount == 0) {
      participant.UnreadMessageCount = 1;
    }
    else {
      participant.UnreadMessageCount++;
    }
    this.UpdateUnreadMessageCount(participant.UserId,message.threadId,participant.UnreadMessageCount);

  }

}
